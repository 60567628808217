import $ from 'jquery';

import Aos from 'aos';


import Swiper from 'swiper';

// import 'swiper/css';

// Import only the Bootstrap components we need
import { Util, Dropdown, Offcanvas, Popover } from 'bootstrap';
//import { Navbar } from 'bootstrap';

//Dependences

//import Swiper, {Navigation, Pagination} from 'swiper';

import 'aos/dist/aos.css'
import '../scss/style.scss';

//import './partials/searching';

const scrollbarWidth = window.innerWidth - document.body.clientWidth
let headerheight = document.querySelector('.main-header').offsetHeight;
document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`)
document.body.style.setProperty("--headerheight", `${headerheight}px`)



$(document).ready(function() {

  $('.btn-search').on('click',function(){
    $(this).closest('.navbar__search').find('.search-form-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  $(window).on('resize', function(){
    headerheight = document.querySelector('.main-header').offsetHeight;
    document.body.style.setProperty("--headerheight", `${headerheight}px`);
  })

  $('.load-more-search').click(function(e){
    e.preventDefault();
    let page = $(this).data('page');
    let search = $(this).data('search');
    let wrapper = $('#search-results');
    $('.loading-loader').show();
    $.ajax({
      data: {
        action: 'ajax_load_more_search',
        page: page,
        search: search
      },
      type: 'get',
      url: ajax_url,
      beforeSend: function (xhr) {
      },
      success: function (data) {
        if(data.success == true) {
          $('.load-more-search').data('page', page+1)
          wrapper.append(data.items)
          if(data.postcount < 10) {
            $('.load-more-search').hide();
          }
        }
        $('.loading-loader').hide();
        // console.log(data);
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    })
  });


  $('.load-more-articles').on('click', function() {
    let pageNumber = parseInt($('.load-more-articles').data('page'))+1;
    let wrapper = $('#related-post-wrapper');
    $('.loading-loader').show();
    $.ajax({
      data: {
        action: 'ajax_load_more_articles',
        page: pageNumber,
      },
      type: 'get',
      url: ajax_url,
      beforeSend: function (xhr) {
      },
      success: function (data) {
        if(data.success == true) {
          $('.load-more-articles').data('page', pageNumber)
          wrapper.append(data.items)
          if(data.postcount < 10) {
            $('.load-more-articles').hide();
          }
        }
        $('.loading-loader').hide();
        // console.log(data);
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    })
  });

  let regionModal = $('#regionleModal');

  if(regionModal.length) {
    let regionModalCookie = getCookie('regionModalCookie');
    // console.log('regionModalCookie ', regionModalCookie)

    regionModal.find('.close').on('click', function() {
      // console.log('closing')
      closeRegionModal()
    });
    regionModal.find('.dropdown-content__button').on('click', function() {
        let region = $(this).attr('data-region');
        // console.log(region)
        regionModal.find('.dropdown-content__button').removeClass('active');
        $(this).addClass('active');
        regionModal.find('.dropbtn span').text(region);
        regionModal.find('.dropdown-content').removeClass('show');
    });

    regionModal.find('.setRegion').on('click', function() {
      let myRegion =  regionModal.find('.dropdown-content__button.active');
      // console.log('myRegion', myRegion.attr('data-region'))
      if(myRegion.attr('data-href')) {
        setCookie('liga_cookie_region', myRegion.attr('data-region'), 30);
        closeRegionModal()
        window.location.href = myRegion.attr('data-href');
      } else {
        setCookie('liga_cookie_region', myRegion.attr('data-region'), 30);
        closeRegionModal()
        location.reload();
      }
    });

    if(regionModalCookie != 'disable') {
       if(regionModalCookie == 'enable') {
        regionModal.addClass('show')
      } else {
        setCookie('regionModalCookie', 'enable', 365);
      }
    }

    function closeRegionModal() {
      regionModal.removeClass('show')
      setCookie('regionModalCookie', 'disable', 365);
    }

  }



  $('.modal .close').on('click', function() {
    $('.modal').removeClass('show')
  });

  $('.orderedlist__row').on('click', function() {
    console.log($(this).attr('aria-expanded'))
    if($(this).hasClass('open')) {
      $('.orderedlist__row').removeClass('open');
      $('.orderedlist__row').find('.orderedlist__question').attr('aria-expanded', 'false')
    } else {
      $('.orderedlist__row').removeClass('open')
      $(this).addClass('open');
      $(this).find('.orderedlist__question').attr('aria-expanded', 'true')
    }
  });

  if($('.dropbtn').length) {
    $('.dropbtn').on('click', function() {
      var dropdownContent = $(this).parent().find('.dropdown-content');

      $('.dropdown-content').each(function() {
          if (!$(this).is(dropdownContent)) {
              $(this).removeClass('show');
          }
      });
      dropdownContent.toggleClass('show');
    });
  }

  if($('.table-of-content').length) {

     $('.table-of-content button, .dropdown-content__button').on('click', function() {

       var targetId = $(this).attr('data');

      if($('#myDropdown').hasClass('show')) {
        $('#myDropdown').removeClass('show');
        $('.dropbtn span').text($(this).text());
      }

       var targetElement = $('#' + targetId);

       $('html, body').animate({
         scrollTop: targetElement.offset().top -70
       }, 800);

       $('.table-of-content button').removeClass('active');

       $(this).addClass('active');
     });

     var tableOfContent = $('.table-of-content');
     var offset = tableOfContent.offset().top;
     var textPage =  $('.text-page');
     var textPageOffsetBottom = textPage.offset().top + textPage.height();
     var headings = $('.text-page h2, .text-page h3, .text-page h4, .text-page h5, .text-page h6');
     var buttons = $('.table-of-content button');



     $(window).scroll(function() {
       var scrollPosition = $(window).scrollTop();

       if (scrollPosition > offset - 100) {

         if(scrollPosition > textPageOffsetBottom - 96 - tableOfContent.height()) {
           tableOfContent.removeClass('sticky');
           tableOfContent.addClass('absolute');

         } else {
           tableOfContent.removeClass('absolute');
           tableOfContent.addClass('sticky');
         }
       } else {
         if(scrollPosition < offset -64) {
           tableOfContent.removeClass('sticky');
         }
       }


       buttons.each(function(index) {
         var buttonsID = '#'+$(this).attr('data');

         var headingOffsetTop = $(buttonsID).offset().top;

         if (scrollPosition >= headingOffsetTop - 100) {
           buttons.removeClass('active');
           $(this).addClass('active');
         }
       });

     });

     $(window).resize(function() {
       offset = tableOfContent.offset().top;
       tableOfContent.removeClass('sticky');
       tableOfContent.removeClass('absolute');

     });
  }


  $('.hamburger.mobile-menu').on('click',function(e){
    //console.log('hamburger');
    e.preventDefault();
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('.main-header').toggleClass('menu-show')
    //$('.main-header').toggleClass('open')
  })



  $('.mobile-menu-wrap .has-children > .nav-link, .mobile-menu-wrap .has-children > .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).closest('.has-children').find('> .show-submenu').toggleClass('active');
    $(this).closest('.has-children').find('> .sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })





  $('.anime-scroll').on('click',function(e){
    e.preventDefault();
    let thishas = $(this).find('.nav-link').attr('href')
    $('html, body').animate({scrollTop: $(thishas).offset().top - 110}, 1000);
  })





  $('.contact-form').on('submit',function(e){
    e.preventDefault();
    let form = $(this);
    $(this).addClass('form-validate');
   /*  $('.be-error').hide();
    $('.error').hide();
    $('.form-control-wrap').removeClass('form-control-wrap--error')
    $('.kontakt-form-wrap .error-response').hide()
    $('.kontakt-form-wrap .success-response').hide() */
    let isValid = true;
    $('.form-control, .form-check-input',form).each(function() {
      $(this).removeClass('is-invalid')
      $(this).closest('.form-floating ').removeClass('is-invalid')
      if ($(this).is(":invalid")) {
        $(this).addClass('is-invalid')
        $(this).closest('.form-floating ').addClass('is-invalid')
        /* $(this).closest('.form-control-wrap').addClass('form-control-wrap--error')
        $(this).closest('.form-group').find('.error').show(); */
        isValid = 0;
      }
    });
    if (isValid) {
      $('.loading-loader').show();
      //const formData = new FormData();
      let data = $(this).serialize();
      //formData.push(data);
      //let file =  $('.form-control[name="subor"]',$(this))[0].files[0];
      //formData.append('file', file);
      //console.log(file)
      $.ajax({
        url: ajax_url,
        type: "post",
        dataType: "json",
        data: new FormData(this),
        processData: false,
        contentType: false,
        success: function(data) {
          //console.log(data);
          $('.loading-loader').hide();
          //console.log(data['responce'])
          if (data['responce'] == 'success') {
            $(form).closest('.contact-form-wrap').find('.success-response').show()
          }else {
            $(form).closest('.contact-form-wrap').find('.error-response').show()
          }
        }
      })
    }
  });

  if($('#moove_gdpr_cookie_info_bar').length) {
    let cookies = getCookie('moove_gdpr_popup');
    //console.log(cookies);
    if (cookies == null) {
      //console.log('jeeee');
      var timerID = setInterval(function() {
        if($('#moove_gdpr_cookie_info_bar').is(':visible')) {
          $('#moove_gdpr_cookie_info_bar .moove-gdpr-infobar-settings-btn').trigger('click');
          $('.gdpr_lightbox-wrap').removeAttr('data-gdpr_lightbox-close');
          clearInterval(timerID);
        }

        //console.log(timerID)
      }, 1000);
    }
  }

  $('.change-region').on('click', function(e) {
    e.preventDefault();

    let region = $(this).data('region');
    region = region.replace(/\s/g, '');
    let url = $(this).attr('href');
    setCookie('liga_cookie_region', region, 30 );
    if(url) {
      window.location.href = url;
    } else {
      location.reload();
    }
  });

});




function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
